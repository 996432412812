import { IoHelpCircleOutline } from "react-icons/io5";
import helpBtn from "./assets/help-button.png";
import zipImg from "./assets/zip.png";
import openAdnImg from "./assets/openInAdnuntius.png";
import toolbox from "./assets/toolbox.png";
import limited from "./assets/limited-zip.png";
import replace from "./assets/replace.png";
import { TbTools } from "react-icons/tb";

export const news = {
    "2024-10-14": (
        <>
            <h3>Replacing creatives</h3>
            Creatives - when expanded - now has the option to replace the video uploaded. <br />
            Replaced videos will show anywhere the creative tag is served.
            <div className="image">
                <img src={replace} alt="Replace creative button"></img>
                <p>The replace button</p>
            </div>
        </>
    ),
    "2024-06-07": (
        <>
            <h3>Limited environment for zipped tags</h3>
            When choosing "Google CM (ZIP)" as your tag format you can now check a box to have the zipped tags work
            within a Google Ad Manager environment.
            <br /> Checking the box adds the "limited" environment needed for Google to serve the tags.
            <div className="image">
                <img src={limited} alt="Checkbox for Google Ad Manager"></img>
                <p>Checkbox for Google Ad Manager</p>
            </div>
        </>
    ),
    "2024-05-13": (
        <>
            <h3>Open in Adnuntius</h3>
            You can now open any creative set in the Adnuntius admin UI by clicking the three dot menu and selecting
            "Open in Adnuntius".
            <div className="image">
                <img src={openAdnImg} alt="The menu item"></img>
                <p>Open in Adnuntius</p>
            </div>
            <br />
            <h3>Copy, rename and delete in list</h3>
            By clicking the <TbTools /> button in a list view you can now select one or more items in a list and either
            copy, rename or delete them using the buttons.
            <div className="image">
                <img src={toolbox} alt="Selecting multiple items"></img>
                <p>Selecting multiple items in a list</p>
            </div>
        </>
    ),
    "2024-05-02": (
        <>
            <h3>Download as zip (Google Campaign Manager)</h3>
            There is now an option to download creative set tags as .zip files for easy bulk upload in Google Campaign
            Manager.
            <br />
            Tags can be downloaded either for specific size ranges, or all at once.
            <div className="image">
                <img src={zipImg} alt="The download as zip dialog"></img>
                <p>Downloading all sizes as a zip file</p>
            </div>
            <h3>Adform tags</h3>
            Adform has now been added to the list of tags on the creative set view.
        </>
    ),
    "2024-04-18": (
        <>
            <h3>Tooltips and help</h3>A new button has been added to the bottom right of the user interface. Pressing
            the <IoHelpCircleOutline /> button will overlay helpful information on top of what you are currently
            viewing.
            <div className="image">
                <img src={helpBtn} alt="The help button"></img>
                <p>The new "help" button</p>
            </div>
            <h3>News</h3>
            This dialog has been added, alongside a fancy button in the navigation bar that lights up if there are any
            unread news.
        </>
    ),
};
