import "../../css/videoEditor.css";
import { useContext, useEffect, useState } from "react";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { CropWindow, CroppedVideo } from "./CropWindow";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { Tooltip } from "react-tooltip";
import { helpParser } from "../../functions/helpParser";

export const VideoEditor = (props: any) => {
    const [file, setFile] = useState<any>(false);
    const [error, setError] = useState<string>("");
    const [fileHovering, setFileHovering] = useState<boolean>(false);
    const [cropArray, setCropArray] = useState<CroppedVideo[]>([]);
    const [cropParams, setCropParams] = useState<any>(null);
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const locationState = useLocation() as {
        state?: {
            cache?: any;
            cropArray?: CroppedVideo[];
            advertiserId?: string;
            folioId?: string;
            creativeSetId?: string;
            cropParams?: any;
        };
    };

    useEffect(() => {
        console.log(locationState);
        if (locationState.state?.cache) {
            setFile(false);
        }
        if (locationState.state?.cropArray?.length) {
            setCropArray(locationState.state?.cropArray);
        }
        if (locationState.state?.cropParams) {
            setCropParams(locationState.state?.cropParams);
        }
    }, [locationState]);
    const navigate = useNavigate();

    const handleDrop = (e: any) => {
        let errorMsg = "";
        e.preventDefault();
        e.stopPropagation();
        props.setCropParams(undefined);
        props.setCropArray([]);
        console.log("did it");
        if (e.dataTransfer.files.length > 1) {
            if (globalState.replaceCreative) {
                setFile(e.dataTransfer.files[0]);
            }
            globalState.set.files(
                Array.from(e.dataTransfer.files).map((f) => {
                    const file = f as File;
                    if (!file.type.includes("video")) {
                        errorMsg = "Files must be of type video";
                    }
                    return { file: f as File };
                })
            );
            console.log("multiple files!");
            if (errorMsg === "") {
                navigate("/new/bulk");
            } else {
                setError(errorMsg);
                globalState.set.files([]);
            }
            return false;
        }
        setFile(e.dataTransfer.files[0]);
    };

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        setFileHovering(true);
        e.stopPropagation();
    };
    const handleDragLeave = (e: any) => {
        e.preventDefault();
        setFileHovering(false);
        e.stopPropagation();
    };

    const handleFileBrowser = () => {
        document.getElementById("file-input")?.click();
    };

    const handleUploadedFile = (e: any) => {
        const input = document.getElementById("file-input") as HTMLInputElement;
        const files = input?.files ? Array.from(input.files) : false;
        props.setCropParams(undefined);
        props.setCropArray([]);
        console.log("did it");
        if (files) {
            if (files.length === 1) {
                setFile(files[0]);
                globalState.set.files([]);
            } else {
                globalState.set.files(
                    files.map((f) => {
                        return { file: f };
                    })
                );
                navigate("/new/bulk");
            }
        }
    };

    const handleContinueClick = (params: any) => {
        props.setFile(file);
        props.setCropArray(params.cropArray);
        props.setCropParams(params);
        const navigateState = { state: {} };
        if (locationState.state?.advertiserId) {
            navigateState.state = locationState.state;
        }
        navigate("/upload/details", navigateState);
    };

    useEffect(() => {
        if (props.file && !locationState.state?.cache) {
            setFile(props.file);
        }
    }, [props.file, file, locationState]);

    return (
        <div className="video-editor">
            <div className="video-drop">
                <div className="center-vertically">
                    <div className={`video-main ${fileHovering && "hovering"}`}>
                        {globalState.replaceCreative && !file && <span className="title">Replace creative</span>}
                        {!file && (
                            <>
                                <input
                                    type="file"
                                    accept="video/*"
                                    multiple={globalState.replaceCreative ? false : true}
                                    id="file-input"
                                    onChange={handleUploadedFile}></input>
                                <div
                                    className="drop-zone"
                                    onDrop={handleDrop}
                                    onDragOver={handleDragEnter}
                                    onDragLeave={handleDragLeave}>
                                    {error ? (
                                        <AiOutlineCloseCircle className="plus error" />
                                    ) : (
                                        <AiOutlinePlusCircle className="plus" />
                                    )}
                                    {error && <p className="small error">{error}</p>}
                                    <p>Drop your video here</p>
                                    <p className="small">
                                        or click{" "}
                                        <span id="file-browser" onClick={handleFileBrowser}>
                                            here
                                        </span>{" "}
                                        to choose file{globalState.replaceCreative ? "" : "s"}
                                    </p>
                                </div>
                            </>
                        )}
                        {file && (
                            <CropWindow
                                file={file}
                                videoId="video"
                                handleContinueClick={handleContinueClick}
                                cropArray={cropArray}
                                cropParams={cropParams}
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* <Tooltip
                anchorSelect=".video-main.false"
                render={() =>
                    helpParser("Drag and drop a file from your computer here to start the video upload process.")
                }
                place="top"
                variant="info"
                opacity={1}
                isOpen={globalState.help && !file}
            /> */}
            <Tooltip
                anchorSelect=".crop-btn"
                render={() => helpParser("Adds a crop tool to the video editor, allowing you to crop the video.")}
                style={{ width: 300 }}
                place="top"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".crop-settings.active i#name input"
                render={() => helpParser("The name for the new video generated by the crop tool.")}
                style={{ width: 300 }}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".crop-settings.active .size-select"
                render={() => helpParser("Choose between predefined height and width ratios or set your own.")}
                style={{ width: 300 }}
                place="bottom-end"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
        </div>
    );
};
