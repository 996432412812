import { useContext, useEffect, useState } from "react";
import { sizes } from "./sizes";
import { Cropper } from "./Cropper";
import { BiSolidCrop, BiTrash } from "react-icons/bi";
import { ScrubbableVideo } from "./ScrubbableVideo";
import { IABSelect } from "./IABSelect";
import { Fallback } from "./fallback/Fallback";
import { GlobalStateManager, globalStateManager, useGlobalState } from "../../contexts/useGlobalState";
export interface CropParams {
    x: string;
    y: string;
    width: string;
    height: string;
    outputWidth: string;
    outputHeight: string;
    fallbackFrame: number;
}

export type SelectedSize = { label: string; value: string };

export type CroppedVideo = {
    id: number;
    name: string;
    customRatio: boolean;
    aspectRatio: false | number;
    selectedSize: undefined | SelectedSize;
    coords: {
        x: number;
        y: number;
    };
    dims: {
        width: number;
        height: number;
    };
    outputDims: {
        width: number;
        height: number;
    };
    fallbackImage: number;
};

export const CropWindow = (props: {
    file: any;
    videoId: string;
    handleContinueClick: any;
    cropArray?: CroppedVideo[];
    cropParams?: any;
}) => {
    const [coords, setCoords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [urlBlob, setUrlBlob] = useState<any>(false);
    const [dims, setDims] = useState<{ w: number; h: number }>({ w: 200, h: 150 });
    const [videoDims, setVideoDims] = useState<{ h: number; w: number; r: number }>({ h: 0, w: 0, r: 0 });
    const [ogVideoDims, setOgVideoDims] = useState<{ h: number; w: number }>({ h: 0, w: 0 });
    const [cropperArray, setCropperArray] = useState<CroppedVideo[]>([]);
    const [cropIterator, setCropIterator] = useState<number>(0);
    const [currentCropper, setCurrentCropper] = useState<CroppedVideo | undefined>(undefined);
    const [videoIsLoaded, setVideoIsLoaded] = useState<boolean>(false);
    const [fallbackFrame, setFallbackFrame] = useState<number>(0);
    const globalState = useContext(useGlobalState) as GlobalStateManager;

    const updateCurrentCropper = (data: Partial<CroppedVideo>) => {
        if (currentCropper) {
            console.log(data);
            setCurrentCropper({ ...currentCropper, ...data });
            const newCropperArray = [...cropperArray];
            const modifiedCropperArray = newCropperArray.map((crop) => {
                if (data.id === crop.id) return data;
                return crop;
            }) as CroppedVideo[];
            setCropperArray(modifiedCropperArray);
        }
    };

    const renderVideo = async (file: any) => {
        const fileReader = new FileReader();
        await fileReader.readAsDataURL(file);

        setUrlBlob(URL.createObjectURL(file));
    };

    const handleDrag = (me: any, o: any) => {
        if (currentCropper) {
            let changed: boolean = false;
            let coords = { ...currentCropper.coords };
            if (o.x < 0) {
                coords.x = 0;
                changed = true;
            }
            if (o.y < 0) {
                coords.y = 0;
                changed = true;
            }
            if (changed) updateCurrentCropper({ ...currentCropper, coords: coords });
        }
    };
    const handleDragEnd = (me: any, o: any) => {
        if (currentCropper) {
            const newCropper = { ...currentCropper };
            newCropper.coords.x = o.x <= 0 ? 0 : o.x;
            newCropper.coords.y = o.y <= 0 ? 0 : o.y;

            updateCurrentCropper(newCropper);
        }
    };
    const handleResize = (me: any, handle: any, el: any, dims: any, coords: any) => {
        if (currentCropper) {
            const { height, width } = el.getBoundingClientRect();
            console.log(width, height);
            const h = height >= 40 ? (height as number) : 40;
            const w = width >= 40 ? (width as number) : 40;

            const newCropper = { ...currentCropper };
            newCropper.dims.height = h;
            newCropper.dims.width = w;
            updateCurrentCropper(newCropper);
        }
    };
    const handleWidth = (e: any, cropId: number) => {
        if (currentCropper) {
            const ratio = currentCropper?.outputDims.height / e.target.value;
            updateCurrentCropper({
                ...currentCropper,
                aspectRatio: ratio,
                dims: {
                    width: e.target.value,
                    height: currentCropper.dims.height,
                },
                outputDims: {
                    width: e.target.value,
                    height: currentCropper?.outputDims.height as number,
                },
            });
        }
    };
    const handleHeight = (e: any, cropId: number) => {
        if (currentCropper) {
            const ratio = currentCropper?.outputDims.width / e.target.value;
            updateCurrentCropper({
                ...currentCropper,
                aspectRatio: ratio,
                dims: {
                    width: currentCropper.dims.width,
                    height: e.target.value,
                },
                outputDims: {
                    height: e.target.value,
                    width: currentCropper?.outputDims.width as number,
                },
            });
        }
    };

    const handleVideoLoad = (e: any) => {
        let w: number;
        let h: number;
        const v = e.target as HTMLVideoElement;
        const ratio = v.videoHeight / v.videoWidth;
        const maxWidth = document.documentElement.clientWidth * 0.6;
        const maxHeight = document.documentElement.clientHeight * 0.7; // 80vh
        const largestDimension: "height" | "width" = v.videoHeight > v.videoWidth ? "height" : "width";
        w = v.videoWidth;
        h = v.videoHeight;
        if (largestDimension === "width") {
            w = maxWidth;
            h = w * ratio;
        }
        if (largestDimension === "height") {
            h = maxHeight > v.videoHeight ? v.videoHeight : maxHeight;
            w = h / ratio;
        }

        setOgVideoDims({ w: v.videoWidth, h: v.videoHeight });
        setVideoDims({ w: w, h: h, r: ratio });
        setDims({ w: Math.floor(w), h: Math.floor(h) });
    };

    const relativePixel = (input: number, dimension: "x" | "y"): number => {
        const pixelRatio = dimension === "x" ? ogVideoDims.w / videoDims.w : ogVideoDims.h / videoDims.h;
        return (2 * Math.round((input * pixelRatio) / 2)) as number;
    };

    const inverseRelativePixel = (input: number, dimension: "x" | "y"): number => {
        const pixelRatio = dimension === "x" ? ogVideoDims.w / videoDims.w : ogVideoDims.h / videoDims.h;
        return (2 * Math.round(input / pixelRatio / 2)) as number;
    };

    const handleUpload = () => {
        const crop_x = 2 * Math.round(coords.x / 2);
        const crop_y = 2 * Math.round(coords.y / 2);
        const crop_w = 2 * Math.round(dims.w / 2);
        const crop_h = 2 * Math.round(dims.h / 2);

        // Translate pixel values
        const handledCropArray: CroppedVideo[] = cropperArray.map((crop) => ({
            ...crop,
            dims: {
                width: relativePixel(crop.dims.width, "x"),
                height: relativePixel(crop.dims.height, "y"),
            },
            coords: {
                x: relativePixel(crop.coords.x, "x"),
                y: relativePixel(crop.coords.y, "y"),
            },
        }));
        // console.log(handledCropArray[0]);
        // return;
        const cropData = {
            x: relativePixel(crop_x, "x"),
            y: relativePixel(crop_y, "y"),
            width: cropperArray.length !== 0 ? relativePixel(crop_w, "x") : ogVideoDims.w,
            height: cropperArray.length !== 0 ? relativePixel(crop_h, "y") : ogVideoDims.h,
            outputWidth: "0",
            outputHeight: "0",
            fallbackFrame: fallbackFrame,
            cropArray: handledCropArray,
        };
        console.log("cropperArray", cropperArray);
        console.log("OG:", ogVideoDims);
        console.log("CropData", cropData);
        props.handleContinueClick(cropData);
    };

    const handleSelectSize: any = (e: any, cropId: number) => {
        const selectedItem = sizes.IAB.filter((s: any) => s.name === e.value)[0];
        if (selectedItem.name === "Custom") {
            const newCropper = { ...currentCropper };
            newCropper.customRatio = true;
            newCropper.aspectRatio = 1.2;
            newCropper.dims = { width: 300, height: 250 };
            newCropper.outputDims = { width: 300, height: 250 };
            newCropper.coords = { x: 0, y: 0 };
            newCropper.selectedSize = { label: selectedItem.name, value: selectedItem.name };
            updateCurrentCropper(newCropper);
            return;
        }

        if (selectedItem.width && selectedItem.height) {
            const newCropper = { ...currentCropper };
            newCropper.customRatio = false;
            let ratio = selectedItem.width! / selectedItem.height!;

            newCropper.aspectRatio = ratio;

            let dims = {} as { height: number; width: number };
            const isTooWide = videoDims.h / ratio > videoDims.w;
            const isTooTall = videoDims.w / ratio > videoDims.h;

            if (ratio < 1) {
                // is tall
                let [w, h] = [selectedItem.width, selectedItem.height];
                if (isTooTall) {
                    h = videoDims.h;
                    w = h * ratio;
                } else if (isTooWide) {
                    w = videoDims.w;
                    h = w / ratio;
                }
                dims = {
                    height: h,
                    width: w,
                };
            } else {
                // is wide
                dims = {
                    height: isTooTall ? videoDims.h : videoDims.w / ratio,
                    width: isTooTall ? videoDims.h * ratio : videoDims.w,
                };
            }

            const clampedWidth = dims.width >= videoDims.w ? videoDims.w : dims.width;
            const clampedHeight = dims.height >= videoDims.h ? videoDims.h : dims.height;

            newCropper.dims = { width: clampedWidth, height: clampedHeight };
            newCropper.outputDims = { width: selectedItem.width, height: selectedItem.height };

            newCropper.coords = { x: 0, y: 0 };
            newCropper.selectedSize = { label: selectedItem.name, value: selectedItem.name };
            updateCurrentCropper(newCropper);
        }
    };
    const rndConf = {
        x: 0,
        y: 0,
        width: dims.w,
        height: dims.h,
    };

    const addCroppedVideo = () => {
        const maxCroppers = globalState.replaceCreative !== "" ? 1 : 5;
        if (cropperArray.length < maxCroppers) {
            let dims = {} as { height: number; width: number };
            if (videoDims.r < 1) {
                // is tall
                dims = {
                    height: videoDims.h,
                    width: videoDims.h * 1.2,
                };
            } else {
                // is wide
                const isTooTall = videoDims.w / 1.2 > videoDims.h;
                dims = {
                    height: isTooTall ? videoDims.h : videoDims.w / 1.2,
                    width: isTooTall ? videoDims.h * 1.2 : videoDims.w,
                };
            }
            const clampedWidth = dims.width >= videoDims.w ? videoDims.w : dims.width;
            const clampedHeight = dims.height >= videoDims.h ? videoDims.h : dims.height;
            dims.height = clampedHeight;
            dims.width = clampedWidth;
            const id = cropIterator + 1;
            const xCoord = videoDims.w / 2 - dims.width / 2;
            const yCoord = videoDims.h / 2 - dims.height / 2;
            const newCroppedVideo: CroppedVideo = {
                id: id,
                name: `Video #${id}`,
                aspectRatio: 1.2,
                customRatio: true,
                selectedSize: { label: "Custom", value: "Custom" },
                coords: {
                    x: 0,
                    y: 0,
                },
                outputDims: {
                    width: 300,
                    height: 250,
                },
                dims: dims,
                fallbackImage: 0,
            };

            setCropperArray([...cropperArray, newCroppedVideo]);
            setCurrentCropper(newCroppedVideo);
            setCropIterator(id);
        }
    };

    const handleSetCropper = (id: number) => {
        const newCropper = cropperArray.filter((c) => c.id === id)[0];
        setCurrentCropper(newCropper);
    };

    const handleDeleteCropper = (id: number) => {
        if (currentCropper && currentCropper.id === id) {
            if (cropperArray[id - 2]?.id) {
                setCurrentCropper(cropperArray[id - 2]);
            } else {
                setCurrentCropper(undefined);
            }
        }
        const newCropArray = cropperArray.filter((c) => c.id !== id);
        setCropperArray(newCropArray);
    };

    const handleChangeName = (e: any) => {
        updateCurrentCropper({ ...currentCropper, name: e.target.value });
    };

    const handleSetFallbackFrame = (n: number) => {
        console.log(n);
        setFallbackFrame(n);
    };

    useEffect(() => {
        renderVideo(props.file);
    }, [props.file]);

    useEffect(() => {
        if (props.cropArray?.length && videoIsLoaded) {
            let i = 0;
            const handledCropArray = props.cropArray.map((crop) => {
                return {
                    ...crop,
                    id: i++,
                    coords: {
                        x: inverseRelativePixel(crop.coords.x, "x"),
                        y: inverseRelativePixel(crop.coords.y, "y"),
                    },
                    dims: {
                        width: inverseRelativePixel(crop.dims.width, "x"),
                        height: inverseRelativePixel(crop.dims.height, "y"),
                    },
                };
            });

            // setCropperArray(props.cropArray);
            // setCurrentCropper(props.cropArray[0]);
            console.log(handledCropArray);
            setCropperArray(handledCropArray);
            setCurrentCropper(handledCropArray[0]);
            setCropIterator(i);
        }
        if (props.cropParams) {
            console.log("params!", props.cropParams);
            setFallbackFrame(props.cropParams.fallbackFrame);
        }
    }, [props.cropArray, videoIsLoaded]);
    return (
        <>
            {urlBlob && (
                <>
                    <div className="row space-between">
                        <ScrubbableVideo
                            video={{
                                id: props.videoId,
                                src: urlBlob,
                                onLoadedMetadata: handleVideoLoad,
                                autoPlay: true,
                                loop: true,
                                muted: true,
                                handleMetaData: () => setVideoIsLoaded(true),
                                style: {
                                    height: videoDims.h,
                                    width: videoDims.w,
                                },
                            }}
                            slider={{
                                style: { width: videoDims.w - 40 + "px", marginTop: "1rem", marginLeft: "-.25rem" },
                            }}
                            cropper={
                                currentCropper !== undefined ? (
                                    <Cropper
                                        handleResizeEnd={handleResize}
                                        handleDragEnd={handleDragEnd}
                                        handleDrag={handleDrag}
                                        rndConf={rndConf}
                                        aspectRatio={currentCropper.aspectRatio}
                                        coords={currentCropper.coords}
                                        dims={currentCropper.dims}
                                        hidden={false}
                                        name={currentCropper.name}
                                        id={currentCropper.id}
                                        overlay={currentCropper.selectedSize?.label === "1920x1080 (with overlay)"}
                                    />
                                ) : (
                                    <></>
                                )
                            }
                            onSetFallbackFrame={handleSetFallbackFrame}
                        />
                        <div className="col top">
                            <div className="row space-between button-container">
                                <div className="crop-btn button" onClick={addCroppedVideo}>
                                    <BiSolidCrop size={20} />{" "}
                                    <span>{globalState.replaceCreative ? "Crop" : "Add crop"}</span>
                                </div>
                                <input
                                    type="button"
                                    className="continue-btn button"
                                    value="Continue"
                                    onClick={handleUpload}
                                />
                            </div>
                            {!cropperArray.length && (
                                <Fallback
                                    videoSrc={urlBlob}
                                    videoElementId="fallback"
                                    parentVideoElementId={props.videoId}
                                    fallbackImage={fallbackFrame || 0}
                                    onSetFallbackImage={handleSetFallbackFrame}
                                    asPopup={false}
                                />
                            )}
                            {!!cropperArray.length &&
                                cropperArray.map((c: CroppedVideo, i: number) => {
                                    return (
                                        <div className="row space-between" key={i}>
                                            <div
                                                className={`crop-settings ${
                                                    currentCropper?.id === c.id ? "active" : ""
                                                }`}
                                                key={i}>
                                                {currentCropper?.id !== c.id && (
                                                    <div
                                                        className="blocker"
                                                        onClick={() => handleSetCropper(c.id)}></div>
                                                )}
                                                <i id="name">
                                                    <label htmlFor={`name-${i}`}></label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id={`name-${i}`}
                                                        value={c.name}
                                                        onChange={handleChangeName}
                                                    />
                                                </i>
                                                <IABSelect
                                                    cropId={c.id}
                                                    selectedSize={c.selectedSize}
                                                    handleSelectSize={handleSelectSize}
                                                />
                                                <i className="dims">
                                                    <label htmlFor="width">Width</label>
                                                    <input
                                                        disabled={!c.customRatio}
                                                        type="number"
                                                        name="width"
                                                        id="width"
                                                        value={c.outputDims.width}
                                                        onChange={(e) => handleWidth(e, c.id)}
                                                    />
                                                </i>
                                                <i className="dims">
                                                    <label htmlFor="height">Height</label>
                                                    <input
                                                        disabled={!c.customRatio}
                                                        type="number"
                                                        name="height"
                                                        id="height"
                                                        value={c.outputDims.height}
                                                        onChange={(e) => handleHeight(e, c.id)}
                                                    />
                                                </i>
                                                <BiTrash
                                                    className="trash-icon"
                                                    onClick={() => {
                                                        handleDeleteCropper(c.id);
                                                    }}
                                                />
                                                <Fallback
                                                    videoSrc={urlBlob}
                                                    videoElementId={`fallback${i}`}
                                                    parentVideoElementId={props.videoId}
                                                    asPopup={false}
                                                    fallbackImage={c.fallbackImage}
                                                    onSetFallbackImage={(n: number) => {
                                                        updateCurrentCropper({ ...c, fallbackImage: n });
                                                    }}
                                                    showButton
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
