import React, { useEffect, useState, useCallback } from "react";
import { RouterProvider, createBrowserRouter, useLocation } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import "react-tabulator/lib/styles.css";
import "./App.css";
import { Background } from "./components/Background";
import { Login } from "./components/Login";
import { NavBar } from "./components/Navbar";
import { FilePicker } from "./components/filePicker/FilePicker";
import { Manage } from "./components/manage/Manage";
import { CropParams, CroppedVideo } from "./components/videoEditor/CropWindow";
import { VideoEditor } from "./components/videoEditor/VideoEditor";
import { defaultGlobalState, globalStateManager, useGlobalState } from "./contexts/useGlobalState";
import { useToken } from "./contexts/useToken";
import { useEnvironment } from "./contexts/useEnvironment";
import { useUser } from "./contexts/useUser";
import pjson from "../package.json";
import { Bulk } from "./components/videoEditor/Bulk/Bulk";
import { getEnvironment } from "./functions/getEnvironment";
import useLocalStorage from "use-local-storage";
import { Tools } from "./components/tools/Tools";
import { Preview } from "./components/preview/Preview";
import { Start } from "./components/Start";
import { Tooltip } from "react-tooltip";
import { Help } from "./components/Help";
import CampaignManagerAuth from "./components/CampaignManagerAuth";

const App = () => {
    const [token, setToken] = useState<string>("");
    const [user, setUser] = useState<any>(false);
    const [file, setFile] = useState<false | any>(false);
    const [cropParams, setCropParams] = useState<false | CropParams>(false);
    const [cropArray, setCropArray] = useState<CroppedVideo[]>([]);
    const [client, setClient] = useState("default");
    const [clientIsSet, setClientIsSet] = useState<boolean>(false);
    const [environment, setEnvironment] = useState<"dev" | "prod">("prod");
    const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const [darkMode, setDarkMode] = useLocalStorage("darkmode", defaultDark ? true : false);
    const [global, setGlobal] = useState(defaultGlobalState);
    const [localToken, setLocalToken] = useState<string>("");
    const globalState = globalStateManager(global, setGlobal);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);

    const DefaultTheme = React.lazy(() => import("./sites/default/default"));
    const ReMediaTheme = React.lazy(() => import("./sites/remedia/remedia"));
    const PrecisTheme = React.lazy(() => import("./sites/precis/precis"));
    const TryTheme = React.lazy(() => import("./sites/try/try"));
    const NoaTheme = React.lazy(() => import("./sites/noa/noa"));
    const FullstakkTheme = React.lazy(() => import("./sites/fullstakk/fullstakk"));
    const IPGTheme = React.lazy(() => import("./sites/ipg-media/ipg-media"));
    const KoblerTheme = React.lazy(() => import("./sites/kobler/kobler"));

    const isPreview = document.location.href.includes("/preview/");
    const CurrentTheme = ({ children }: any) => {
        return (
            <>
                <React.Suspense fallback={<></>}>
                    {/* <DefaultTheme /> */}
                    {client === "default" && <DefaultTheme />}
                    {client === "remedia" && <ReMediaTheme />}
                    {client === "precis" && <PrecisTheme />}
                    {client === "try" && <TryTheme />}
                    {client === "noa" && <NoaTheme />}
                    {client === "fullstakk" && <FullstakkTheme />}
                    {client === "ipg" && <IPGTheme />}
                    {client === "kobler" && <KoblerTheme />}
                </React.Suspense>
                {children}
            </>
        );
    };

    const handleSetClient = useCallback(
        (newClient: string) => {
            globalState.set.client(newClient);
            setClient(newClient);
        },
        [globalState.set]
    );

    useEffect(() => {
        // if (document.location.href.includes("client")) {
        //     setClient(document.location.href.split("client=")[1]);
        // }
        if (!clientIsSet) {
            if (document.location.href.includes("fullstakk")) {
                handleSetClient("fullstakk");
            }
            if (document.location.href.includes("ipg")) {
                handleSetClient("ipg");
            }
            if (document.location.href.includes("try-studio")) {
                handleSetClient("try");
            }
            if (document.location.href.includes("kobler")) {
                handleSetClient("kobler");
            }
            setClientIsSet(true);
        }
    }, [client, handleSetClient]);

    useEffect(() => {
        setDarkMode(globalState.darkmode);
    }, [globalState.darkmode, setDarkMode]);

    useEffect(() => {
        const env = getEnvironment();
        setEnvironment(env);
        const lToken = localStorage.getItem("token");
        lToken && setLocalToken(lToken);
    }, []);

    useEffect(() => {
        console.log(global.token);
        setIsLoggedIn(global.token !== "");
        console.log(global.token);
    }, [global.token]);

    const handleUpdateUserProfile = (data: any) => {
        setUser(data);
    };

    const previewRouter = createBrowserRouter([{ path: "preview/:previewPayload", element: <Preview /> }]);

    const router = createBrowserRouter([
        {
            path: "/",
            element: <NavBar user={user} />,
            children: [
                {
                    path: "upload/details",
                    element: (
                        <FilePicker
                            setToken={setToken}
                            user={user}
                            file={file}
                            cropParams={cropParams}
                            setCropParams={setCropParams}
                            cropArray={cropArray}
                            setCropArray={setCropArray}
                        />
                    ),
                },
                {
                    path: "new",
                    element: (
                        <VideoEditor
                            setToken={setToken}
                            setFile={setFile}
                            file={file}
                            setCropParams={setCropParams}
                            setCropArray={setCropArray}
                        />
                    ),
                },
                {
                    path: "replace",
                    element: (
                        <VideoEditor
                            setToken={setToken}
                            setFile={setFile}
                            file={file}
                            setCropParams={setCropParams}
                            setCropArray={setCropArray}
                        />
                    ),
                },
                {
                    path: "/",
                    element: <Start />,
                },
                {
                    path: "new/bulk",
                    element: <Bulk setToken={setToken} setFile={setFile} />,
                },
                {
                    path: "manage",
                    element: <Manage user={user} setToken={setToken} />,
                },
                {
                    path: "advertiser/:advertiserId",
                    element: <Manage user={user} setToken={setToken} />,
                },
                {
                    path: "creativeset/:creativeSetId",
                    element: <Manage user={user} setToken={setToken} setFile={setFile} />,
                },
                {
                    path: "folio/:folioId",
                    element: <Manage user={user} setToken={setToken} />,
                },
                {
                    path: "tools",
                    element: <Tools user={user} setToken={setToken} />,
                },
                {
                    path: "help",
                    element: <Help />,
                },
            ],
        },
        {
            path: "GoogleAuthentication",
            element: <CampaignManagerAuth />,
        },
    ]);

    return (
        <div className="studio" data-theme={darkMode ? "darkmode" : ""} data-client={client}>
            {/* {logo && <img className="logo" src={logo}></img>} */}
            <CurrentTheme></CurrentTheme>
            <useEnvironment.Provider value={environment}>
                <useGlobalState.Provider value={globalState}>
                    <Background client={client} user={user} />
                    {!isLoggedIn && !isPreview && (
                        <div id="login">
                            <Login setToken={setToken} updateProfile={handleUpdateUserProfile} user={user}></Login>
                        </div>
                    )}
                    {global.token && (
                        <div id="main">
                            <useToken.Provider value={token}>
                                <useUser.Provider value={user}>
                                    {token && user && (
                                        <>
                                            <RouterProvider router={router} />
                                        </>
                                    )}
                                </useUser.Provider>
                            </useToken.Provider>
                        </div>
                    )}
                    {isPreview && (
                        <div id="main">
                            <RouterProvider router={previewRouter} />
                        </div>
                    )}
                </useGlobalState.Provider>
            </useEnvironment.Provider>
            <span className="version">
                {pjson.version}
                {environment === "dev" ? " D" : ""}
            </span>
            <Tooltip
                anchorSelect=".version"
                render={() => <p>The current version of Studio.</p>}
                place="top-end"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
        </div>
    );
};

export default App;
